<template>
  <v-app>
    <div class="row">
      <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>
                    Rooms
                  </h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\ Rooms
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <v-btn v-if="checkIsAccessible('rooms', 'create')"
                         @click="addRooms()"
                         class="mt-4 btn btn-primary  mr-1"
                         dark>
                    <i class="fa fa-plus"></i>
                    Add room
                  </v-btn>

                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" md="5">
                    <v-text-field label="Title"
                                  type="text"
                                  dense   v-on:keyup.enter="getRooms()"
                                  outlined
                                  v-model="search.title"/>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select label="Status" outlined dense :items="active_statuses" item-text="title"
                              item-value="value" v-on:keyup.enter="getRooms()" clearable
                              v-model="search.active"></v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="text-right">
                    <v-btn
                        @click="getRooms()"
                        class="btn btn-primary btn-search "
                        :loading="loading"
                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div class="table-responsive" >
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table " v-if="!loading">
                  <thead>
                  <tr class="px-3">
                    <th class="wrap-column px-3">Title</th>
                    <th class="wrap-column px-3">Room number</th>
                    <th class="wrap-column px-3" v-if="setting.enable_level">Level</th>
                    <th class="wrap-column px-3" v-if="setting.enable_buildings">Building</th>
                    <th class="wrap-column px-3">Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>

                  <draggable v-if="rooms.length" @change="sort" v-model="rooms" class="text-left" tag="tbody"
                             handle=".handle">
                    <tr v-for="(room, index) of rooms" :key="room.id">
                      <td class="wrap-column px-3" scope="row">
                        <i class="cursor-drag fas fa-sort handle pr-2"></i>
                        <a href="#" @click="editRooms(room.id)" class="mr-2"> {{ room.title }}</a>

                      </td>
                      <td class="wrap-column px-3">
                        {{ room.room_number }}
                      </td>
                      <td v-if="setting.enable_level" class="wrap-column px-3">
                        {{ room.level_number }}
                      </td>
                      <td v-if="setting.enable_buildings" class="wrap-column px-3">
                        {{ room.building_number }}
                      </td>
                      <td class="wrap-column px-3 pt-3">
                      <span class="badge text-lg`"
                            v-bind:class="{ 'badge-success': room.is_active, 'badge-danger': !room.is_active }"
                      >{{ room.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>
                      <td class="px-2">
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover ">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('rooms', 'edit')">
                              <a href="#" class="navi-link" @click="editRooms(room.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                <span class="navi-text"> Edit </span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('rooms', 'delete')">
                              <a href="#" class="navi-link"
                                 @click="deleteRooms(room.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                <span class="navi-text">  Delete</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>

                      </td>
                    </tr>
                  </draggable>
                  <tr v-if="rooms.length==0">
                    <td colspan="6" class="text-center">
                      <strong>No data available to display.</strong>
                    </td>
                  </tr>

                </table>
                <b-pagination
                    v-if="rooms.length > 0"
                    class="pull-right mt-7"
                    @input="getRooms"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
              </div>
            </div>
            <create-and-update ref="room" @refresh_room="getRooms"></create-and-update>
          </div>
      </div>
    </div>
  </v-app>
</template>
<script>

import {SiteSettingMixin} from "@/mixins/SiteSettingMixin";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from 'vuedraggable'
import RoomsService from "@/core/services/rooms/RoomsService";

const roomService = new RoomsService();
export default {
  name: "rooms",
  mixins: [SiteSettingMixin],
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      loading: true,
      active_statuses: [
        {title: 'Active', value: "active"},
        {title: 'Inactive', value: "in_active"}
      ],
      rooms: [],
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "active",
        is_parent: "null"
      },
      dragging: false
    };
  },
  mounted() {
    this.getSetting();
    this.getRooms();

  },
  methods: {
    getRooms() {
      this.loading=true;
      roomService
          .paginate(this.search)
          .then(response => {
            this.rooms = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch(error => {
            // console.log(error);
            this.loading = false;
          });
    },
    addRooms() {
      this.$refs["room"].showModal();
    },
    editRooms(id) {
      this.$refs["room"].showModal(id);
    },
    manageCategory() {
      this.$refs['manageCategory'].showModal();
    },

    deleteRooms(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item permanently ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            roomService
                .delete(id)
                .then(response => {
                  this.$snotify.success('Room deleted')
                  this.getRooms();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
    sort() {
      roomService.sort(this.rooms).then((res) => {
        this.$snotify.success("Date sorted");
        this.getRooms()
      }).catch(err => {
        this.$snotify.success("Error Sorting data");
      })
    },
    detail(id) {
      this.$router.push({name: "room-photo", params: {id: id}});
    }
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
