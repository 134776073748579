<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="700">
            <v-card>

              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }}  room
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="cancelForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Title" v-model="room.title" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.room.title.$error">This information is required</span>

                        </v-col>
                        <v-col cols="12" >
                            <v-text-field label="Room number" type="number" v-model="room.room_number" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.room.room_number.$error">This information is required</span>
                        </v-col>
                        <v-col cols="12" v-if="setting.enable_level">
                            <v-text-field label="Level number" v-model="room.level_number" outlined dense>

                            </v-text-field>

                        </v-col>
                        <v-col cols="12"  v-if="setting.enable_buildings">
                            <v-text-field label="Building number" v-model="room.building_number" outlined dense>

                            </v-text-field>

                        </v-col>
                        <v-col cols="12">
                          Status
                            <v-switch  v-model="room.is_active"   color="primary"  :label="room.is_active?'Active':'Inactive'">

                            </v-switch>

                        </v-col>

                    </v-row>

                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="btn btn-standard cancel-btn"
                    depressed
                    @click="cancelForm">
                  Cancel
                </v-btn>
                <v-btn v-if="checkIsAccessible('rooms', 'create')"
                       class="text-white ml-2 btn btn-primary"
                       depressed
                    :loading="isBusy"
                    @click="createOrUpdate()"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import RoomsService from "@/core/services/rooms/RoomsService";
    const roomService = new RoomsService();
    import {SiteSettingMixin} from "@/mixins/SiteSettingMixin";

    export default {
        name:'rooms',
        mixins:[SiteSettingMixin],
        validations: {
            room: {
                title: {required},
                room_number: {required},

            }
        },
        data() {
            return {
                programs:[],
                dialog: false,
                edit: false,
                sizeExceed: false,
                isBusy: false,
                search: {
                    active: "active",
                    is_parent: 1,
                },
                room: {
                    title: null,
                    room_number: null,
                    level_number: null,
                    building_number: null,
                    is_active: true
                },
            };
        },
        mounted() {
            this.getSetting();
        },
        methods: {
            cancelForm(){
                this.resetForm()
                this.hideModal()
            },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.getRoom(id)
                }else{
                    this.resetForm();
                }
                this.dialog = true;
                // this.$refs["my-modal"].show();
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, getRoom(id) {
                roomService.show(id).then(response => {
                    this.room = response.data.rooms
                })
            },

            createOrUpdate() {
                this.$v.room.$touch();
                if (this.$v.room.$error) {
                    setTimeout(() => {
                        this.$v.room.$reset();
                    }, 3000);
                } else {
                    let fd = this.room;
                    if (this.edit) {
                        this.updateRoom(fd);
                    } else {
                        this.createRoom(fd);
                    }
                }
            },
            updateRoom(fd) {
                this.isBusy = true;
                roomService
                    .update(this.room.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.resetForm();
                        this.hideModal();
                        this.$emit("refresh_room");
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            createRoom(fd) {
                this.isBusy = true;
                roomService
                    .store(fd)
                    .then((response) => {
                        console.log(response)
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");
                        this.hideModal();
                        this.$emit("refresh_room");
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                    });
            },
            resetForm() {
                this.$v.room.$reset();
                this.edit = false;
                this.room = {
                    title: null,
                    is_active: true
                };
            }
        }
    };
</script>
